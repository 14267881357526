/* You can add global styles to this file, and also import other style files */

/* Button Styles */

.btn-dribble {
  background-color: #e49ef1 !important;
}

.btn-default {
  background-color: #b1b1b1 !important;
}

/* End of Button Styles */

.navbar.navbar-transparent {
  background-color: #cfe3f3;
}

.navbar-search {
  border: #0d0d0d solid 2px;
  border-top-color: #009de6;
}

.main-content.overlap {
  border-radius: 3px;
}

.nav-link {
  color: black !important;
}

.navbar-brand {
  color: #000 !important;
}

$black-bg: purple !default;
$black-color: pink !default;
$btn-color: red !default;
